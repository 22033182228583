<style lang="scss">
    @media (max-width: 575.98px) {
        .testovi {
            .card-body {
                font-size: 1.6rem !important;
                margin-bottom: 15px !important;
                .form-check {
                    position: relative;
                    display: inline-block;
                    padding-left: 1.25rem;
                    width: 100%;
                }
                .form-check-label {
                    margin-bottom: 0;
                    font-size: 1.3rem !important;
                    color: black;
                    width: 90%;
                }
                .row.answerholder {
                    margin: 0px;
                }
                .image-holder {
                    text-align: center;
                }
                .image-respons {
                    max-width: 40vh;
                    max-height: 40vh;
                }
                img.image-respons.image-raskrsnica {
                    max-width: 60vh !important;
                    max-height: 60vh !important;
                    min-height: 30vh;
                }

                button.btn.btn-icon.btn-outline-primary.waves-effect.nextprevbuton {
                    max-width: 40pt;
                    width: 40pt;
                    margin-left: 12pt;
                    max-height: 40pt;
                    height: 40pt;
                    border: 1px solid red !important;
                    color: red;
                }
            }
            a.navbar-brand.pitanja-brand {
                text-align: center;
            }
            img.logo-zameni.logo-pitanja {
                width: 80%;
            }
        }

    }

</style>
<template>
    <div class="testovi">
        <section id="basic-tabs-components">
            <a data-v-26084dc2="" class="navbar-brand pitanja-brand"><span data-v-26084dc2="" class="brand-logo"></span><img data-v-26084dc2="" src="img/crvena.69b20487.png" class="logo-zameni logo-pitanja"></a>
            <div class="card text-center mb-3" v-if="CurrentQuestion">
                <div class="card-body">
                    <h4 class="card-title">{{bodovi}}/{{Test.type=='teory'?'120':'20'}}</h4>
                    <p class="card-text" v-if="Test.type=='teory'&&bodovi<108||Test.type!=='teory'&&bodovi<18">
                        Nazalost Niste Polozili
                    </p>
                    <button type="button" class="btn btn-primary waves-effect waves-float waves-light" v-on:click="Ponovi()">Ponovi</button>
                </div>
            </div>
            <div class="row match-height">
                <!-- Basic Tabs starts -->
                <div class="col-xl-12 col-lg-12" v-if="Questions">
                    <div class="card">
                        <div class="card-body" v-bind:key="Questions[QuestionNumber].id">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <div class="number-question">{{QuestionNumber+1}}</div>
                                <strong class="question-head"  >{{Questions[QuestionNumber]['text']}}</strong>
                                    <div class="row answerholder"  v-for="QuestionAnswer in Questions[QuestionNumber]['answers']" :key="QuestionAnswer.text+'#'+Questions[QuestionNumber].id">
                                <div class="form-check form-check" >
                                    <input class="form-check-input" type="checkbox" v-bind:id="QuestionAnswer.text+'#'+Questions[QuestionNumber].id" v-bind:value="QuestionAnswer.value" v-model="QuestionAnswer.value">
                                    <label class="form-check-label" v-bind:for="QuestionAnswer.text+'#'+Questions[QuestionNumber].id">
                                        <span v-if="CurrentQuestion&&!QuestionAnswer.tacan">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 27 27" fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="22" y1="6" x2="6" y2="22" style="
    color: red;
"></line><line x1="6" y1="6" x2="22" y2="22" style="
    color: red;
"></line></svg>
                                        </span>
                                        <span v-if="CurrentQuestion&&QuestionAnswer.tacan">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="22 6 9 17 4 12" style="
    color: green;
"></polyline></svg>
                                        </span>

                                        {{QuestionAnswer.text}}

                                    </label>
                                    </div>
                                </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 image-holder">
                                <img class="image-respons" v-bind:class="{'image-raskrsnica':QuestionNumber>=29}" v-if="Questions[QuestionNumber]['image']" v-bind:src="serverRoot+'/storage/app/public/'+Questions[QuestionNumber]['url']">
                                </div>
                                <div class="correctornot" v-if="CurrentQuestion">
                                    <span v-if="Questions[QuestionNumber].points" class="correct-answer-text">Točno</span>
                                    <span v-if="!Questions[QuestionNumber].points" class="incorrect-answer-text">Netočno</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card text-center mb-3" v-if="QuestionNumber!=Questions.length-1">
                        <div class="card-body no-border">
                            <button type="button" class="btn btn-icon btn-outline-primary waves-effect nextprevbuton" v-on:click="PrevQuestion()" :disabled="QuestionNumber==0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                            </button>
                            <button type="button" class="btn btn-icon btn-outline-primary waves-effect nextprevbuton" v-on:click="NextQuestion()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                            </button>
                        </div>
                    </div>
                    <div class="card text-center mb-3" v-if="!CurrentQuestion&&QuestionNumber==Questions.length-1">
                        <div class="card-body">
                            <button type="button" class="btn btn-primary waves-effect waves-float waves-light finish-test" v-on:click="Polazi()">Zavrsi</button>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>
<script>
    import api from '../api'
  export default {
    name: 'TestoviPolaganje',
    components: {
    },
    data() {
        return {
        Test:null,
        Questions:null,
        CurrentQuestion:0,
        QuestionNumber:0,
            bodovi:0,
        serverRoot:process.env.VUE_APP_SERVER_ROOT,
      }
    },
    mounted(){
        let ID=this.$route.params.id;
        let UserId=this.$route.params.uid;
        this.GetTest(ID).then(data => {
            this.Test=data.data.Test;
            this.Questions=data.data.Test.questions
            this.QuestionNumber=0;
            for(let i=0;i<this.Questions.length;i++)
            {
                this.Questions[i].answers=JSON.parse(this.Questions[i].answers);
                this.Questions[i].correctanswers=JSON.parse(this.Questions[i].correctanswers);
                for(let j=0;j<this.Questions[i].answers.length;j++)
                {
                    let tacan=false;
                    for(let k=0;k<this.Questions[i].correctanswers.length;k++)
                    {
                        if(this.Questions[i].answers[j]==this.Questions[i].correctanswers[k]){ tacan=true;}
                    }
                    let newAnswer={
                        text:this.Questions[i].answers[j],
                        value:false,
                        tacan:tacan,
                    }
                    this.Questions[i].answers[j]=newAnswer;
                }

            }

            this.loading=false;
        });
    },
    methods:{
        async GetTest(ID) {
            return api.GetTest(ID);
        },
        Polazi:function(){
            this.bodovi=0;
            this.QuestionNumber=0;
            for(let i=0;i<this.Questions.length;i++)
            {
                let tacanodgovor=true;
                for(let j=0;j<this.Questions[i].answers.length;j++)
                {
                    let tacan=false;
                    for(let k=0;k<this.Questions[i].correctanswers.length;k++)
                    {
                        if(this.Questions[i].answers[j].text==this.Questions[i].correctanswers[k]) tacan=true;
                    }
                    if(tacan && !this.Questions[i].answers[j].value || !tacan&&this.Questions[i].answers[j].value) tacanodgovor=false;
                }
                if(tacanodgovor)
                {
                    this.bodovi+=this.Questions[i].points;

                }else
                {
                    this.Questions[i].points=0;
                }
                this.CurrentQuestion++
            }
            let ID=this.$route.params.id;
            let UserId=this.$route.params.uid;
            return api.postKorisnikTest(UserId,this.Questions,ID);
        },
        NextQuestion:function(){
            this.QuestionNumber=this.QuestionNumber+1;
        },
        PrevQuestion:function(){
            this.QuestionNumber=this.QuestionNumber-1;
        },
        Ponovi:function(){
            this.bodovi=0;
            this.QuestionNumber=0;
            this.CurrentQuestion=0;
        }
    }
  }
</script>
<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
    .image-respons {
        max-width: 20vh;
        max-height: 20vh;
    }
    .card-body {
        font-size: 1.5rem;
        border: 1px solid gray;
        padding: 5px;
        margin: 5px;
    }
    .card-body.correct {
        background: #12e812;
        margin: 20px;

    }
    .card-body.incorrect {
        background: #f44336;
        color: white;
        margin: 20px;
    }
    strong.question-head.incorrect {
        color: white;
    }
    label.form-check-label.incorrect {
        color: white;
    }
    strong.question-head.correct {
        color: white;
    }
    label.form-check-label.correct {
        color: white;
    }

    .form-check-label {
        margin-bottom: 0;
        font-size: 1.3rem;
        color: black;
    }
    strong.question-head {
        color: black;
    }
    input[type="checkbox"]{
        -webkit-appearance: initial !important;
        appearance: initial;
        background: white;
        border: 1px solid black;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        position: relative;
        margin-top: 8px;
        vertical-align: top;
    }
    input[type="checkbox"]:checked {
        background: white;
    }
    input[type="checkbox"]:checked:after {
        /* Heres your symbol replacement */
        content: "X";
        color: black;
        /* The following positions my tick in the center,
         * but you could just overlay the entire box
         * with a full after element with a background if you want to */
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        /*
         * If you want to fully change the check appearance, use the following:
         * content: " ";
         * width: 100%;
         * height: 100%;
         * background: blue;
         * top: 0;
         * left: 0;
         */
    }
    button.btn.btn-primary.waves-effect.waves-float.waves-light.finish-test {
        height: 140%;
        width: 45%;
        background: #4caf50 !important;
        font-weight: bold;
        font-size: 1.5rem;
        border: 1px solid #4caf50 !important;
    }
    .card-body.no-border {
        border-bottom: none;
        border: none;
    }
    .number-question {
        border: 2px solid green;
        background: #00800061;
        margin-left: 10pt;
        height: 35px;
        width: 45px;
        text-align: center;
        vertical-align: middle;
        font-weight: bold;
        margin-left: 0px;
    }
    .row.answerholder {
        margin: 0px;
    }
    .row.answerholder {
        margin-top: 20px !important;
    }
    .row.answerholder.correct {
        border: 2px solid #27ff00;
        background: green;
    }
    span.correct-answer-text {
        color: green;
        font-size: 29px;
        font-weight: bold;
    }
    span.incorrect-answer-text {
        color: red;
        font-size: 29px;
        font-weight: bold;
    }
    .correctornot {
        text-align: center;
        width: 100%;
    }
</style>
